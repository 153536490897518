import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { signup, isLoggedIn } from '../../services/authentication/action';
import { signUpSchema } from '../../common/validation';
import { getLabel } from '../../../common/getLabel';
import CustomReCAPTCHA from '../../common/CustomReCAPTCHA';
import { validateRecaptcha } from '../../common/common';
import { handleKeyDown, scrollToFirstError } from '../../../common/common';

const SignUp = () => {
    const navigate = useNavigate();
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [resetRecaptcha, setResetRecaptcha] = useState(false);
    const fieldRefs = useRef({});

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/');
            return;
        }
    }, [navigate]);

    const initialValues = {
        first_name: '',
        last_name: '',
        user_email: '',
        username: '',
        user_type: '1',
        user_password: '',
        confirm_password: '',
        news_subscribe: false,
        terms_condition: false,
    };

    const formik = useFormik({
        initialValues,
        validationSchema: signUpSchema,
        validateOnChange: true,
        onSubmit: async (values, { resetForm }) => {
            try {
                await validateRecaptcha(recaptchaToken);
                const postData = {
                    first_name: values.first_name.trim(),
                    last_name: values.last_name.trim(),
                    user_email: values.user_email.trim(),
                    username: values.username,
                    user_type: values.user_type,
                    user_password: values.user_password,
                    confirm_password: values.confirm_password,
                    news_subscribe: values.news_subscribe ? 1 : 0,
                    recaptcha: recaptchaToken
                };
                try {
                    const response = await signup(postData);
                    if (response === true) {
                        resetForm();
                        setRecaptchaToken(null);
                        setResetRecaptcha(true);
                        setTimeout(() => setResetRecaptcha(false), 0);
                        navigate('/');
                    }
                } catch (error) { }
            } catch (error) {
                formik.setErrors({ recaptcha: error.message });
            }
        }
    });

    useEffect(() => {
        scrollToFirstError(formik, fieldRefs);
    }, [formik.errors, formik.touched, formik.isSubmitting]);

    const handleChange = (e) => {
        formik.handleChange(e);
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaToken(value);
    };

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 mx-auto">
                        <div className="login-bg">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <h2>{getLabel('LBL_Signup')}</h2>
                                    <div className="col-lg-12 sign-up-label profile--type">
                                        <label className="label-text">{getLabel('LBL_I_am_a:')}</label>
                                        {['1', '2', '3', '4'].map(value => (
                                            <div key={value} className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="user_type"
                                                    value={value}
                                                    checked={formik.values.user_type === value}
                                                    onChange={handleChange}
                                                />
                                                <label className="form-check-label" htmlFor={`gridRadios${value}`}>
                                                    {getLabel(`LBL_${['Parent', 'Student', 'Trainer', 'Coach/Recruiters'][value - 1]}`)}
                                                </label>
                                            </div>
                                        ))}
                                        {formik.touched.user_type && formik.errors.user_type && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.user_type}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_First_Name')}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="first_name"
                                                onChange={handleChange}
                                                placeholder={getLabel('LBL_*First_Name')}
                                                ref={el => fieldRefs.current.first_name = el}
                                                onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()}
                                            />
                                        </div>
                                        {formik.touched.first_name && formik.errors.first_name && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.first_name}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Last_Name')}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="last_name"
                                                onChange={handleChange}
                                                placeholder={getLabel('LBL_*Last_Name')}
                                                ref={el => fieldRefs.current.last_name = el}
                                                onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()}
                                            />
                                        </div>
                                        {formik.touched.last_name && formik.errors.last_name && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.last_name}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Email_Address')}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="user_email"
                                                onChange={handleChange}
                                                placeholder={getLabel('LBL_*Email_Address')}
                                                ref={el => fieldRefs.current.user_email = el}
                                            />
                                        </div>
                                        {formik.touched.user_email && formik.errors.user_email && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.user_email}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_User_Name')}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="username"
                                                onChange={handleChange}
                                                placeholder={getLabel('LBL_*User_Name')}
                                                ref={el => fieldRefs.current.username = el}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                        {formik.touched.username && formik.errors.username && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.username}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Password')}</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="user_password"
                                                onChange={handleChange}
                                                placeholder={getLabel('LBL_*Password')}
                                                ref={el => fieldRefs.current.user_password = el}
                                            />
                                        </div>
                                        {formik.touched.user_password && formik.errors.user_password && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.user_password}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Confirm_Password')}</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="confirm_password"
                                                onChange={handleChange}
                                                placeholder={getLabel('LBL_*Confirm_Password')}
                                                ref={el => fieldRefs.current.confirm_password = el}
                                            />
                                        </div>
                                        {formik.touched.confirm_password && formik.errors.confirm_password && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.confirm_password}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-6">
                                        <ul>
                                            <li>8 or more characters</li>
                                            <li>One uppercase character</li>
                                            <li>One number</li>
                                            <li>One special character</li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="terms_condition"
                                                id="terms_condition"
                                                onChange={handleChange}
                                                ref={el => fieldRefs.current.terms_condition = el}
                                            />
                                            <label className="form-check-label" htmlFor="terms_condition">
                                                I agree to the <Link to="/terms-conditions">Terms & Conditions</Link>
                                            </label>
                                        </div>
                                        {formik.touched.terms_condition && formik.errors.terms_condition && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.terms_condition}</span>
                                        )}
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="news_subscribe"
                                                id="news_subscribe"
                                                onChange={handleChange}
                                                ref={el => fieldRefs.current.news_subscribe = el}
                                            />
                                            <label className="form-check-label" htmlFor="news_subscribe">
                                                {getLabel('LBL_Sign_me_up_for_the_QB_Hit_List_e-newsletter._I_understand_that_I_can_change_my_subscription_preferences_at_any_time.')} <Link to="/privacy-policy">{getLabel('LBL_Privacy_Policy')}</Link>
                                            </label>
                                        </div>
                                        {formik.touched.news_subscribe && formik.errors.news_subscribe && (
                                            <span style={{ color: '#b94a48' }}>{formik.errors.news_subscribe}</span>
                                        )}
                                    </div>

                                    <div className="col-md-12 mt-4">
                                        <CustomReCAPTCHA onChange={handleRecaptchaChange} reset={resetRecaptcha} />
                                        {formik.errors.recaptcha && (
                                            <span style={{ color: '#b94a48' }} ref={el => fieldRefs.current.recaptcha = el}>{formik.errors.recaptcha}</span>
                                        )}
                                    </div>

                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn btn-primary">{getLabel('LBL_SIGN_UP!')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="return">
                            <Link to="/"><strong><i className='bx bx-arrow-back'></i> {getLabel('LBL_RETURN_TO_HOME_PAGE')}</strong></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUp;
