import React from 'react'
import { getUserProfileStyles } from '../../../common/common';

const SearchBar = (props) => {
    const { handleFilterUpdate, handleFilter, filterdata } = props
    const handleSubmit = (event) => {
        event.preventDefault();
        handleFilter();
    };
    return (
        <>
            <div className="col-lg-12">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="filter">
                                <h4>FILTER BY:</h4>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4 form-group state">
                            <select className="form-select" name="class" onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)} value={filterdata.class} aria-label="Default select example">
                                <option value="">Class</option>
                                <option value="2024">Class of 2024</option>
                                <option value="2025">Class of 2025</option>
                                <option value="2026">Class of 2026</option>
                                <option value="2027">Class of 2027</option>
                                <option value="2028">Class of 2028</option>
                                <option value="2029">Class of 2029</option>
                                <option value="2030">Class of 2030</option>
                                <option value="2031">Class of 2031</option>
                                <option value="2032">Class of 2032</option>
                            </select>
                        </div>

                        <div className="col-lg-6 col-sm-6 form-group-class">
                            <select className="form-select" name="style" onChange={(e) => handleFilterUpdate(e.target.name, e.target.value)} value={filterdata.style} aria-label="Default select example">
                                <option value="">Style</option>
                                {Object.keys(getUserProfileStyles).map((key) => (
                                    <option key={key} value={key}>
                                        {getUserProfileStyles[key]}
                                    </option>
                                ))}

                            </select>
                        </div>

                        <div className="col-lg-2 col-sm-2">
                            <div className="go-btn">
                                <button type="submit" name="btn_submit" className="btn btn-primary" onClick={handleFilter}>GO</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SearchBar
