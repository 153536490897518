import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from "react-router-dom"
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, isLoggedIn } from '../../services/authentication/action';
import { loginSchema } from '../../common/validation';
import { getUniqueId, scrollToFirstError } from '../../../common/common';
import { getLabel } from '../../../common/getLabel';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate("")

    useEffect(() => {
        if (isLoggedIn() === true) {
            navigate('/qbhl-admin');
            return;
        }
    }, [navigate]);

    const location = useLocation();
    const [previousURL, setPreviousURL] = useState("");
    useEffect(() => {
        if (location.state?.from) {
            setPreviousURL(location.state.from);
        }
    }, [location]);

    const initialValues = {
        username: "",
        userPassword: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: loginSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                const postData = {
                    username: values.username,
                    password: values.userPassword,
                    device_id: getUniqueId()
                };
                dispatch(login(postData)).then((response) => {
                    if (isLoggedIn() === true) {
                        navigate(previousURL ? previousURL : '/qbhl-admin');
                        return;
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }

    })

    const fieldRefs = useRef({});

    useEffect(() => {
        scrollToFirstError(formik, fieldRefs);
    }, [formik.errors, formik.touched, formik.isSubmitting]);

    const handleChange = (e) => {
        formik.handleChange(e)
    };
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 mx-auto">
                        <div className="login-bg">
                            <div className="row">
                                <h2>{getLabel('LBL_Login')}</h2>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="col-lg-12">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Username_or_Email')}</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="username"
                                                ref={el => fieldRefs.current.username = el}
                                                onChange={handleChange}
                                                value={formik.values.username}
                                                placeholder={getLabel('LBL_*Username')}
                                            />
                                            {formik.touched.username && formik.errors.username && (
                                                <span style={{ color: '#b94a48' }}>{formik.errors.username}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="from-group">
                                            <label>{getLabel('LBL_Password')}</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                id="userPassword"
                                                name="userPassword"
                                                ref={el => fieldRefs.current.userPassword = el}
                                                onChange={handleChange}
                                                value={formik.values.userPassword}
                                                placeholder={getLabel('LBL_*Password')}
                                            />
                                            {formik.touched.userPassword && formik.errors.userPassword && (
                                                <span style={{ color: '#b94a48' }}>{formik.errors.userPassword}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn btn-primary">{getLabel('LBL_Login')}</button>
                                    </div>
                                </form>
                                <div className="col-lg-12">
                                    <div className="forgot">
                                        <p><Link to="/forgot-password">{getLabel('LBL_Forgot_username_or_password?')}</Link></p>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="return">
                            <Link to="/"><strong><i className='bx bx-arrow-back'></i> {getLabel('LBL_RETURN_TO_HOME_PAGE')}</strong></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
