import React from 'react'
import { useFormik } from "formik";
import { udpateAccountDetails } from '../../../services/account/action';
import { accountSettingSchema } from '../../../common/validation';
import { getLabel } from '../../../../common/getLabel';

const AccountSettingForm = ({ accountDetails }) => {
    const initialValues = {
        first_name: (accountDetails) ? accountDetails.user_fname : '',
        last_name: (accountDetails) ? accountDetails.user_lname : '',
        user_username: (accountDetails) ? accountDetails.user_username : '',
        user_email: (accountDetails) ? accountDetails.user_email : '',
        user_type: (accountDetails) ? accountDetails.user_type : '',
        user_is_news_subscribe: (accountDetails) ? accountDetails.user_is_news_subscribe : '',
        user_password: ''
    };
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: accountSettingSchema,
        validateOnChange: false,
        onSubmit: async (values, { resetForm }) => {
            const postData = {
                first_name: values.first_name,
                last_name: values.last_name,
                user_email: initialValues.user_email,
                user_password: values.user_password,
            }
            try {
                const response = await udpateAccountDetails(postData);
                if (response === true) {
                    resetForm({
                        values: {
                            ...values,
                            user_password: '',
                        },
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
    });
    const handleChange = (e) => {
        formik.handleChange(e);
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <h2>{getLabel('LBL_Account_Settings')}</h2>
                    <div className="col-lg-12 sign-up-label">
                        <label className="label-text">{getLabel('LBL_Contact_Information')}</label>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                        <div className="from-group">
                            <label>{getLabel('LBL_First_Name')}</label>
                            <input type="text" name="first_name" value={formik.values.first_name} onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()} onChange={handleChange} className="form-control" placeholder={getLabel('LBL_First_Name')} aria-label="First name" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                        <div className="from-group">
                            <label>{getLabel('LBL_Last_Name')}</label>
                            <input type="text" name="last_name" value={formik.values.last_name} onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()} onChange={handleChange} className="form-control" placeholder={getLabel('LBL_Last_Name')} aria-label="last name" />
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="from-group">
                            <label>{getLabel('LBL_User_Name')}</label>
                            <input type="text" name="user_username" disabled value={formik.values.user_username} className="form-control" placeholder={getLabel('LBL_User_Name')} aria-label="user name" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                        <div className="from-group">
                            <label>{getLabel('LBL_Email_Address')}</label>
                            <input type="email" name="user_email" disabled value={formik.values.user_email} onChange={handleChange} className="form-control" placeholder={getLabel('LBL_Email_Address')} aria-label="email address" />
                        </div>
                        {formik.touched.user_email && formik.errors.user_email && (
                            <span style={{ color: '#b94a48' }}>{formik.errors.user_email}</span>
                        )}
                    </div>

                    <div className="col-lg-6 col-sm-6">
                        <div className="from-group">
                            <label>{getLabel('LBL_Update_Password')}</label>
                            <input type="password" name="user_password" value={formik.values.user_password} onChange={handleChange} className="form-control" placeholder={getLabel('LBL_New_Password')} aria-label="password" />
                        </div>
                        {formik.touched.user_password && formik.errors.user_password && (
                            <span style={{ color: '#b94a48' }}>{formik.errors.user_password}</span>
                        )}
                    </div>

                    <div className="col-lg-6 col-sm-6">
                        <ul>
                            <li>8 or more characters</li>
                            <li>One uppercase character</li>
                            <li>One number</li>
                            <li>One special character</li>
                        </ul>
                    </div>

                    <div className="col-12 mt-4">
                        <button type="submit" className="btn btn-primary">{getLabel('LBL_Save_Changes')}</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AccountSettingForm
