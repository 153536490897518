import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from "formik";
import { deleteProfileImage, setupProfileData, uploadProfileImage, uploadProfileCoverImage, getProfileOffers, setupOffers } from '../../../services/account/action';
import { useNavigate } from "react-router-dom";
import { profileSchema } from '../../../common/validation';
import { getLabel } from '../../../../common/getLabel'
import { ImageDeleteModal } from '../../../common/imageDeleteModal';
import ProfileImageModal from '../profileImageModal';
import { baseUrl } from '../../../common/config';
import { getOfferTypes, scrollToFirstError } from '../../../../common/common';
import { InputMask } from '@react-input/mask';
import CustomDatePicker from '../../../../common/CustomDatePicker';

const PlayerInformation = (props) => {
    const { profileData, profileId, userIsPremium, useDynamicFields, stateListing, maxNumVideoFields } = props;
    const [profileImage, setProfileImage] = useState((profileData && profileData.image) ? profileData.image : []);
    const [profileCoverImage, setProfileCoverImage] = useState((profileData && profileData.cover_image) ? profileData.cover_image : []);
    const [imageDeleteModalshow, setImageDeleteModalShow] = useState(false);
    const [profileImageModalShow, setProfileImageModalShow] = useState(false);
    const [imageDeleteType, setImageDeleteType] = useState('');
    const [imageUploadType, setImageUploadType] = useState('');
    const [imageData, setImageData] = useState(null);
    // const { fields: highlightField, setFields: setHighlightFields, addField: addHighlightFields, removeField: removeHighlightField, handleChange: handleHighlightField } = useDynamicFields({ url: '' });
    const { fields: honorFields, setFields: setHonorFields, addField: addHonorFields, removeField: removeHonorField, handleChange: handleHonorFieldChange } = useDynamicFields({ award_date: '', award_name: '' });
    const { fields: collegeFields, setFields: setCollegeFields, addField: addCollegeFields, removeField: removeCollegeField, handleChange: handleCollegeFieldChange } = useDynamicFields({ poffer_id: '', poffer_commit: '0', poffer_college: '', poffer_date: '', poffer_type: '' });

    const social_media = (profileData && profileData.social_media) ? JSON.parse(profileData.social_media) : '';
    const video_info = (profileData && profileData.video) ? JSON.parse(profileData.video) : '';
    const height_feet = (profileData && profileData.height_feet) ? JSON.parse(profileData.height_feet) : '';
    const height_inch = (profileData && profileData.height_inch) ? JSON.parse(profileData.height_inch) : '';
    const about = (profileData && profileData.about) ? JSON.parse(profileData.about) : '';
    const weight = (profileData && profileData.weight) ? JSON.parse(profileData.weight) : '';
    const school = (profileData && profileData.school) ? JSON.parse(profileData.school) : '';
    const coach = (profileData && profileData.coach) ? JSON.parse(profileData.coach) : '';
    const academic_stats = (profileData && profileData.academic_stats) ? JSON.parse(profileData.academic_stats) : '';

    const initialValues = {
        profile_id: (profileData) ? profileData.uprofile_id : '0',
        first_name: (profileData) ? profileData.uprofile_fname : '',
        last_name: (profileData) ? profileData.uprofile_lname : '',
        phone_no: (profileData) ? profileData.uprofile_phone_no : '',
        website: (profileData) ? profileData.uprofile_website : '',
        style: (profileData) ? profileData.uprofile_style : '',
        email: (profileData) ? profileData.uprofile_email : '',
        address: (profileData) ? profileData.uprofile_address : '',
        city: (profileData) ? profileData.uprofile_city : '',
        state_code: (profileData) ? profileData.uprofile_state_code : '',
        zip: (profileData) ? profileData.uprofile_zip : '',
        height_feet: height_feet,
        height_inch: height_inch,
        social_media_facebook: (social_media && social_media.facebook) ? social_media.facebook : '',
        social_media_instagram: (social_media && social_media.instagram) ? social_media.instagram : '',
        social_media_twitter: (social_media && social_media.twitter) ? social_media.twitter : '',
        social_media_snapchat: (social_media && social_media.snapchat) ? social_media.snapchat : '',
        video: (video_info && video_info.url) ? video_info.url : '',
        grad_class: (profileData) ? profileData.uprofile_grad_class : '',
        about: about,
        weight: weight,
        hs_name: (school && school.hs_name) ? school.hs_name : '',
        hs_city: (school && school.hs_city) ? school.hs_city : '',
        hs_state: (school && school.hs_state) ? school.hs_state : '',
        coach_name: (coach && coach.coach_name) ? coach.coach_name : '',
        coach_phone: (coach && coach.coach_phone) ? coach.coach_phone : '',
        act_score: (academic_stats && academic_stats.act_score) ? academic_stats.act_score : '',
        sat_score: (academic_stats && academic_stats.sat_score) ? academic_stats.sat_score : '',
        gpa_score: (academic_stats && academic_stats.gpa_score) ? academic_stats.gpa_score : '',
    };

    const videoIntialFields = { url: '' };
    const [numVideoFields, setNumVideoFields] = useState(1);
    const [highlightField, setHighlightFields] = useState([videoIntialFields]);
    const addVideoField = () => {
        setHighlightFields([...highlightField, { ...videoIntialFields }]);
        setNumVideoFields(numVideoFields + 1);
    };
    const removeVideoField = (index) => {
        const updatedFields = [...highlightField];
        updatedFields.splice(index, 1);
        setHighlightFields(updatedFields);
        setNumVideoFields(index);
    };
    const handleVideoChange = (index, field, value) => {
        const updatedFields = [...highlightField];
        updatedFields[index][field] = value;
        setHighlightFields(updatedFields);
    };

    useEffect(() => {
        if (profileData && profileData.video && Array.isArray(JSON.parse(profileData.video)) && profileData.video.length > 0) {
            setHighlightFields(JSON.parse(profileData.video));
            setNumVideoFields(JSON.parse(profileData.video).length);
        }
        if (profileData && profileData.awards && Array.isArray(JSON.parse(profileData.awards)) && profileData.awards.length > 0) {
            setHonorFields(JSON.parse(profileData.awards));
        }
        if (profileId) {
            loadOffers();
        }
    }, [profileData]);

    const loadOffers = async () => {
        const response = await getProfileOffers({ "profile_id": profileId });
        if (response && response.rows) {
            setCollegeFields(response.rows);
            const offerCommit = response.rows.some(item => item.poffer_commit !== 0);
            formik.setFieldValue('commit', offerCommit ? 'some_value' : 'none');
        }
    }

    const navigate = useNavigate("")
    const filteredHighlightField = highlightField.filter(item => item.url !== '');
    const filteredHonorFields = honorFields.filter(item => item.award_date !== '' || item.award_name !== '');
    const filteredCollegeFields = collegeFields.filter(item => item.poffer_id !== '' || item.poffer_commit !== '' || item.poffer_college !== '' || item.poffer_date !== '' || item.poffer_type !== '');

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: profileSchema,
        validateOnChange: false,
        onSubmit: async (values) => {
            const postData = {
                profile_id: values.profile_id,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone_no: values.phone_no,
                website: values.website,
                grad_class: values.grad_class,
                style: values.style,
                address: values.address,
                city: values.city,
                state_code: values.state_code,
                zip: values.zip,
                profile_info: {
                    social_media: {
                        facebook: values.social_media_facebook,
                        snapchat: values.social_media_snapchat,
                        instagram: values.social_media_instagram,
                        twitter: values.social_media_twitter,
                    },
                    height_feet: values.height_feet,
                    height_inch: values.height_inch,
                    about: values.about,
                    weight: values.weight,
                    school: {
                        hs_name: values.hs_name,
                        hs_city: values.hs_city,
                        hs_state: values.hs_state
                    },
                    coach: {
                        coach_name: values.coach_name,
                        coach_phone: values.coach_phone
                    },
                    academic_stats: {
                        act_score: values.act_score,
                        sat_score: values.sat_score,
                        gpa_score: values.gpa_score
                    },
                    video: filteredHighlightField,
                    awards: filteredHonorFields,
                }
            }
            if (userIsPremium) {
                postData.orderId = userIsPremium.order_id
            }
            try {
                const response = await setupProfileData(postData);
                if (response.success === true && response.data.profile_Id) {
                    const offerPostedData = {
                        profile_id: response.data.profile_Id,
                        offers: filteredCollegeFields
                    }
                    await setupOffers(offerPostedData);
                }
                navigate('/qbhl-admin');
            } catch (error) {
                console.log(error);
            }
        },
    });
    const handleChange = (e) => {
        if (e.target.name == 'commit') {
            filteredCollegeFields.forEach(item => item.poffer_commit = "0");
            if (e.target.value !== 'none') {
                filteredCollegeFields[e.target.value].poffer_commit = "1";
            }
        }
        formik.handleChange(e);
    };

    const handleImageDeleteModalShow = (imageType) => {
        setImageDeleteType(imageType);
        setImageDeleteModalShow(true);
    };

    const handleProfileImageModalShow = (imageType, imageData) => {
        setImageUploadType(imageType);
        setImageData(imageData);
        setProfileImageModalShow(true);
    };

    const handleDeleteImage = async () => {
        try {
            const data = { profile_id: profileId, image_type: imageDeleteType };
            await deleteProfileImage(data);
            if (imageDeleteType == 1) {
                setProfileImage([]);
            } else {
                setProfileCoverImage([]);
            }
            handleImageDeleteModalClose();
        } catch (error) { }
    };

    const handleImageDeleteModalClose = () => setImageDeleteModalShow(false);
    const handleProfileImageModalClose = () => setProfileImageModalShow(false);

    const handleImageChange = async (file) => {
        try {
            const formData = new FormData();
            formData.append("profile_id", profileId);
            if (imageUploadType == 1) {
                formData.append("profile_image", file);
                const response = await uploadProfileImage(formData);
                if (response.success) {
                    setProfileImage(response.data);
                }
            } else {
                formData.append("profile_image", file);
                const response = await uploadProfileCoverImage(formData);
                if (response.success) {
                    setProfileCoverImage(response.data);
                }
            }
            handleProfileImageModalClose();
        } catch (error) {
            console.log("error", error);
        }
    };

    const fieldRefs = useRef({});

    useEffect(() => {
        scrollToFirstError(formik, fieldRefs);
    }, [formik.errors, formik.touched, formik.isSubmitting]);

    const handleRadioChange = (e) => {
        const { value } = e.target;
        const updatedFields = collegeFields.map((item, index) => ({
            ...item,
            poffer_commit: index === parseInt(value) ? '1' : '0'
        }));
        setCollegeFields(updatedFields);
        formik.setFieldValue('commit', value);
    };

    const handleCollegeDateChange = (index, field, date) => {
        handleCollegeFieldChange(index, field, date);
    };
    const handleHonorDateChange = (index, field, date) => {
        handleHonorFieldChange(index, field, date);
    };

    return (
        <>
            <h4>{getLabel('LBL_Player_Information')}</h4>
            <div className="bg-white-tranier mb-5">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_*First_Name')}</label>
                                <input type="text" className="form-control" name="first_name" ref={el => fieldRefs.current.first_name = el} onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()} value={formik.values.first_name} placeholder={getLabel('LBL_*First_Name')} onChange={handleChange} aria-label="First name" />
                            </div>
                            {formik.touched.first_name && formik.errors.first_name && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.first_name}</span>
                            )}
                        </div>


                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_*Last_Name')}</label>
                                <input type="text" className="form-control" name="last_name" ref={el => fieldRefs.current.last_name = el} onKeyDown={(e) => /[a-zA-Z ]/.test(e.key) || e.preventDefault()} value={formik.values.last_name} placeholder={getLabel('LBL_*Last_Name')} onChange={handleChange} aria-label="Last ame" />
                            </div>
                            {formik.touched.last_name && formik.errors.last_name && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.last_name}</span>
                            )}
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_Player_Mailing_Address')}</label>
                                <input type="text" className="form-control" name="address" value={formik.values.address} placeholder={getLabel('LBL_Player_Mailing_Address')} onChange={handleChange} aria-label="Business Name" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_Email_Address')}</label>
                                <input type="text" className="form-control" name="email" value={formik.values.email} placeholder={getLabel('LBL_Email_Address')} aria-label="Address" onChange={handleChange} />
                            </div>
                            {formik.touched.email && formik.errors.email && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.email}</span>
                            )}
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_Player_City')}</label>
                                <input type="text" className="form-control" name="city" value={formik.values.city} placeholder={getLabel('LBL_Player_City')} aria-label="Address" onChange={handleChange} />
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_State')}</label>
                                <select as="select" className="form-select" name="state_code" value={formik.values.state_code} aria-label="Default select example" onChange={handleChange}>
                                    <option value="">{getLabel('LBL_State')}</option>
                                    {stateListing && stateListing().map((state, index) => (
                                        <option key={index} value={state}>{state}</option>
                                    ))}
                                </select>
                            </div>
                        </div>


                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_Zip')}</label>
                                <input type="text" className="form-control numberonly" name="zip" value={formik.values.zip} placeholder={getLabel('LBL_Zip')} aria-label="Zip" onChange={handleChange} />
                            </div>
                        </div>


                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_Phone')}</label>
                                <InputMask type="text" className="form-control numberonly" mask="(___) ___-____" replacement={{ _: /\d/ }} name="phone_no" value={formik.values.phone_no} placeholder={getLabel('LBL_Phone')} aria-label="Phone" onChange={handleChange} />
                            </div>
                        </div>

                        <div className="col-lg-12 my-4">
                            {profileId ? (
                                <div className="cover-photo">
                                    {profileCoverImage && profileCoverImage.length > 0 && (
                                        <div className="cover-close-proflie" onClick={() => handleImageDeleteModalShow(2)}><i className="bx bx-x"></i></div>
                                    )}
                                    <div className="cover-photo-image">
                                        <figure>
                                            {profileCoverImage && profileCoverImage.length > 0 ? (
                                                <img src={`${baseUrl}/${profileCoverImage[0].afile_physical_path}`} alt="Profile" className="img-fluid" />
                                            ) : (
                                                <img src="/images/profile-detail-image.jpg" alt="Profile" className="img-fluid" />
                                            )}
                                        </figure>
                                        <div className="change-image" onClick={() => handleProfileImageModalShow(2, profileCoverImage)}>
                                            <i className='bx bx-camera'></i>
                                            {getLabel('LBL_Cover_Photo')}
                                        </div>
                                    </div>
                                    <div className="profile-picture">
                                        <div className="proile-pic-user">
                                            {profileImage && profileImage.length > 0 ? (
                                                <img src={`${baseUrl}/${profileImage[0].afile_physical_path}`} alt="Profile" className="img-fluid" />
                                            ) : (
                                                <img src="/images/qbh-hit.jpg" alt="Profile" className="img-fluid" />
                                            )}
                                        </div>
                                        {profileImage && profileImage.length > 0 && (
                                            <div className="profile-close-proflie" onClick={() => handleImageDeleteModalShow(1)}><i className="bx bx-x"></i></div>
                                        )}
                                        <div className="profile-icon" onClick={() => handleProfileImageModalShow(1, profileImage)}>
                                            <i className='bx bx-camera'></i>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <p className="save-profile">{getLabel('LBL_Once_you_save_your_profile_you_may_add_images.')}</p>
                            )}
                        </div>

                        <div className="col-lg-4 col-sm-4">
                            <div className="from-group">
                                <label>{getLabel('LBL_Height')}</label>

                                <select as="select" className="form-select" name="height_feet" value={formik.values.height_feet} onChange={handleChange} aria-label="Default select example">
                                    <option value="">{getLabel('LBL_-Feet-')}</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4">
                            <div className="from-group">
                                <label>{getLabel('LBL_Inches')}</label>
                                <select as="select" className="form-select" name="height_inch" value={formik.values.height_inch} onChange={handleChange} aria-label="Default select example">
                                    <option value="">{getLabel('LBL_-Inches-')}</option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-4">
                            <div className="from-group">
                                <label>{getLabel('LBL_Weight')} </label>
                                <input type="text" className="form-control" placeholder={getLabel('LBL_Lbs.')} ref={el => fieldRefs.current.weight = el} aria-label="Phone" name="weight" value={formik.values.weight} onChange={handleChange} />
                                {formik.touched.weight && formik.errors.weight && (
                                    <span style={{ color: '#b94a48' }}>{formik.errors.weight}</span>
                                )}
                            </div>
                        </div>


                        <div className="col-lg-12 mt-4">
                            <div className="from-group profile--position">
                                <label className="Position">{getLabel('LBL_Position')} </label>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="style" ref={el => fieldRefs.current.style = el} id="style1" value="1" checked={formik.values.style == "1"} onChange={handleChange} />
                                    <label className="form-check-label" htmlFor="style1">{getLabel('LBL_Pro_Style')}</label>
                                </div>


                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="style" id="style2" value="2" checked={formik.values.style == "2"} onChange={handleChange} />
                                    <label className="form-check-label" htmlFor="style2">{getLabel('LBL_Dual_Threat')}</label>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="style" id="style3" value="3" checked={formik.values.style == "3"} onChange={handleChange} />
                                    <label className="form-check-label" htmlFor="style3">{getLabel('LBL_Wide_Receiver')}</label>
                                </div>


                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="style" id="style4" value="4" checked={formik.values.style == "4"} onChange={handleChange} />
                                    <label className="form-check-label" htmlFor="style4">{getLabel('LBL_Tight_End')}</label>
                                </div>
                            </div>
                            {formik.touched.style && formik.errors.style && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.style}</span>
                            )}
                        </div>

                        <div className="col-lg-12 mt-4">
                            <div className="bio">
                                <label>{getLabel('LBL_Social_Accounts')}</label>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="bio">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="social_media_twitter" value={formik.values.social_media_twitter} onChange={handleChange} placeholder={getLabel('LBL_Twitter')} aria-label="Twitter username" aria-describedby="twitter" />
                                    <span className="input-group-text basic-addon2" id="twitter"><i className='bx bxl-twitter'></i></span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="bio">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="social_media_snapchat" value={formik.values.social_media_snapchat} onChange={handleChange} placeholder={getLabel('LBL_Snapchat')} aria-label="Snapchat Profile" aria-describedby="snapchat" />
                                    <span className="input-group-text basic-addon2" id="snapchat"><i className='bx bxl-snapchat'></i></span>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="bio">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="social_media_instagram" value={formik.values.social_media_instagram} onChange={handleChange} placeholder={getLabel('LBL_Instagram')} aria-label="Instagram Profile" aria-describedby="instagram" />
                                    <span className="input-group-text basic-addon2" id="instagram"><i className='bx bxl-instagram'></i></span>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="bio">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" name="social_media_facebook" value={formik.values.social_media_facebook} onChange={handleChange} placeholder={getLabel('LBL_Facebook')} aria-label="Facebook Profile" aria-describedby="facebook" />
                                    <span className="input-group-text basic-addon2" id="facebook"><i className='bx bxl-facebook'></i></span>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-12 mt-5">
                            <div className="bio">
                                <label>{getLabel('LBL_Personal_Statement')}</label>
                                <textarea rows="4" cols="100" maxLength="300" placeholder={getLabel('LBL_Personal_Statement')} name="about" value={formik.values.about} onChange={handleChange}></textarea>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-4">
                            <div className="bio">
                                <label>{getLabel('LBL_School_Information')}</label>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_School_Name')}</label>
                                <input type="text" className="form-control" name="hs_name" ref={el => fieldRefs.current.hs_name = el} value={formik.values.hs_name} onChange={handleChange} placeholder={getLabel('LBL_*School_Name')} aria-label="Address" />
                            </div>
                            {formik.touched.hs_name && formik.errors.hs_name && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.hs_name}</span>
                            )}
                        </div>


                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label></label>
                                <select as="select" name="grad_class" ref={el => fieldRefs.current.grad_class = el} value={formik.values.grad_class} className="form-select" aria-label="Default select example" onChange={handleChange}>
                                    <option value="">{getLabel('LBL_*Graduation_Class')}</option>
                                    <option value="2024">Class of 2024</option>
                                    <option value="2025">Class of 2025</option>
                                    <option value="2026">Class of 2026</option>
                                    <option value="2027">Class of 2027</option>
                                    <option value="2028">Class of 2028</option>
                                    <option value="2029">Class of 2029</option>
                                    <option value="2030">Class of 2030</option>
                                    <option value="2031">Class of 2031</option>
                                    <option value="2032">Class of 2032</option>
                                </select>
                            </div>
                            {formik.touched.grad_class && formik.errors.grad_class && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.grad_class}</span>
                            )}
                        </div>


                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_City')}</label>
                                <input type="text" className="form-control" name="hs_city" ref={el => fieldRefs.current.hs_city = el} value={formik.values.hs_city} onChange={handleChange} placeholder={getLabel('LBL_*City')} aria-label="Address" />
                            </div>
                            {formik.touched.hs_city && formik.errors.hs_city && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.hs_city}</span>
                            )}
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <label>{getLabel('LBL_State')}</label>
                                <select as="select" name="hs_state" value={formik.values.hs_state} ref={el => fieldRefs.current.hs_state = el} onChange={handleChange} className="form-select" aria-label="Default select example">
                                    <option value="">{getLabel('LBL_*State')}</option>
                                    {stateListing && stateListing().map((state, index) => (
                                        <option key={index} value={state}>{state}</option>
                                    ))}
                                </select>
                            </div>
                            {formik.touched.hs_state && formik.errors.hs_state && (
                                <span style={{ color: '#b94a48' }}>{formik.errors.hs_state}</span>
                            )}
                        </div>

                        <div className="col-lg-12">
                            <div className="from-group bio">
                                <label>{getLabel('LBL_Coach_Information')}</label>
                            </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group">
                                <input type="text" className="form-control" name="coach_name" value={formik.values.coach_name} onChange={handleChange} placeholder={getLabel('LBL_Coach_Name')} aria-label="Coach name" />
                            </div>
                        </div>


                        <div className="col-lg-6 col-sm-6">
                            <div className="from-group bio">
                                <InputMask type="text" className="form-control numberonly" mask="(___) ___-____" replacement={{ _: /\d/ }} name="coach_phone" value={formik.values.coach_phone} onChange={handleChange} placeholder={getLabel('LBL_Coach_Phone')} aria-label="Coach Phone" />
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="from-group bio">
                                <label>{getLabel('LBL_Highlight_Reels')}</label>
                                <p>{getLabel('LBL_Basic_members_can_include_up_to_2_videos.')}</p>
                                <p><a href="https://www.hudl.com/support/v3/an-athletes-guide-to-hudl/highlights/embed-your-highlight-reel" target="_blank">{getLabel('LBL_How_to_get_embed_for_Hudl')}</a></p>
                                <p><a href="https://support.google.com/youtube/answer/171780?hl=en" target="_blank">{getLabel('LBL_How_to_get_YouTube_video_URL_link')} </a></p>
                                <p>{getLabel('LBL_Copy_&_Paste_Youtube_link_as_shown_in_the_below_example:')}</p>
                                <p>(https://youtu.be/CmzKQ3PSrow)</p>

                                <div id="highlight-film">
                                    <div className="row">
                                        {highlightField && highlightField.map((field, index) => (
                                            <React.Fragment key={index}>
                                                <div className="col-lg-11">
                                                    <div className="from-group">
                                                        <input type="text" className="form-control" name={`url[${index}]`} value={field.url} onChange={(e) => handleVideoChange(index, 'url', e.target.value)} placeholder={getLabel('LBL_Highlight_Film')} />
                                                    </div>
                                                </div>
                                                {index > 0 && (
                                                    <div className="col-lg-1 required_inp">
                                                        <div className="from-group">
                                                            <input type="button" className="inputRemove" value="X" onClick={() => removeVideoField(index)}></input>
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                {numVideoFields <= maxNumVideoFields && (
                                    <div className="add-more">
                                        <button type="button" className="add_input" onClick={() => addVideoField()}><i className='bx bx-plus'></i> {getLabel('LBL_Add_Highlight_Reel')}</button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-12 mt-4">
                            <div className="bio">
                                <label>{getLabel('LBL_College_Offers_/_Commits')}</label>
                            </div>
                        </div>

                        {collegeFields.map((field, index) => {
                            return (
                                <div className="col-lg-12" key={index}>
                                    <div className="row">
                                        <div className="col-lg-2 commit-options">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="commit" id={`commit[${index}]`} value={index} onChange={handleRadioChange} checked={field.poffer_commit == "1"} />
                                                <label className="form-check-label" htmlFor={`commit[${index}]`}>{getLabel('LBL_Commit')}</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <input type="text" name={`poffer_college[${index}]`} className="form-control" placeholder={getLabel('LBL_College')} aria-label="College" value={field.poffer_college} onChange={(e) => handleCollegeFieldChange(index, 'poffer_college', e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <CustomDatePicker
                                                    selectedDate={field.poffer_date ? new Date(field.poffer_date) : null}
                                                    onDateChange={(date) => handleCollegeDateChange(index, 'poffer_date', date)}
                                                    dateFormat="yyyy-MM-dd"
                                                    className="form-control"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    isClearable={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-group">
                                                <select className="form-select" name={`poffer_type[${index}]`} value={field.poffer_type} onChange={(e) => handleCollegeFieldChange(index, 'poffer_type', e.target.value)}>
                                                    <option value="">{getLabel('LBL_-poffer_type-')}</option>
                                                    {Object.keys(getOfferTypes).map((key) => (
                                                        <option key={key} value={key}>
                                                            {getOfferTypes[key]}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <input type="hidden" name="poffer_id" value={field.poffer_id} />
                                        <input type="hidden" name="poffer_commit" value={field.poffer_commit} />
                                        {index > 0 && (
                                            <div className="col-lg-1 required_inp">
                                                <div className="form-group">
                                                    <input type="button" className="inputRemove" value="X" onClick={() => removeCollegeField(index)} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                        <div className="col-lg-12">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="commit" value="none" onChange={handleRadioChange} checked={formik.values.commit === 'none'} />
                                <label className="form-check-label" htmlFor="commit">{getLabel('LBL_No_Commitment')}</label>
                            </div>
                            <div className="add-more">
                                <button type="button" className="add_input" onClick={addCollegeFields}><i className='bx bx-plus'></i> {getLabel('LBL_Add_Offer_/_Commit')}</button>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-4">
                            <div className="bio">
                                <label>{getLabel('LBL_Honors_/_Awards')}</label>
                            </div>
                        </div>

                        {honorFields.map((field, index) => (
                            <div className="col-lg-12" key={index}>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="form-group">
                                            <CustomDatePicker
                                                selectedDate={field.award_date ? new Date(field.award_date) : null}
                                                onDateChange={(date) => handleHonorDateChange(index, 'award_date', date)}
                                                dateFormat="yyyy-MM-dd"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" name={`award_name[${index}]`} className="form-control" placeholder={getLabel('LBL_name_/_Type')} value={field.award_name} aria-label="Name/Type" onChange={(e) => handleHonorFieldChange(index, 'award_name', e.target.value)} />
                                        </div>
                                    </div>
                                    {index > 0 && (
                                        <div className="col-lg-1 required_inp">
                                            <div className="form-group">
                                                <input type="button" className="inputRemove" value="X" onClick={() => removeHonorField(index)}></input>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        <div className="col-lg-12">
                            <div className="add-more">
                                <button type="button" className="add_input" onClick={addHonorFields}><i className='bx bx-plus'></i> {getLabel('LBL_Add Honor_/_Award')}</button>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-4">
                            <div className="bio">
                                <label>{getLabel('LBL_Academic_Stats')}</label>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>{getLabel('LBL_ACT_Score')}</label>
                                <input type="text" className="form-control" name="act_score" value={formik.values.act_score} onChange={handleChange} placeholder={getLabel('LBL_ACT_Score')} aria-label="ACT Score" />
                                {formik.touched.act_score && formik.errors.act_score && (
                                    <span style={{ color: '#b94a48' }}>{formik.errors.act_score}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>{getLabel('LBL_SAT_Score')}</label>
                                <input type="text" className="form-control" name="sat_score" value={formik.values.sat_score} onChange={handleChange} placeholder={getLabel('LBL_SAT_Score')} aria-label="SAT Score" />
                                {formik.touched.sat_score && formik.errors.sat_score && (
                                    <span style={{ color: '#b94a48' }}>{formik.errors.sat_score}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label>{getLabel('LBL_Current_GPA')}</label>
                                <input type="text" className="form-control" name="gpa_score" value={formik.values.gpa_score} onChange={handleChange} placeholder={getLabel('LBL_Current_GPA')} aria-label="Current GPA" />
                                {formik.touched.gpa_score && formik.errors.gpa_score && (
                                    <span style={{ color: '#b94a48' }}>{formik.errors.gpa_score}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="from-group">
                                <button type="submit" className="btn btn-default btn-sm btn-save btn-umber">{getLabel('LBL_Save_Changes')}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {profileId && (
                <>
                    <ProfileImageModal imageData={imageData} profileImageModalShow={profileImageModalShow} handleProfileImageModalClose={handleProfileImageModalClose} handleImageChange={handleImageChange} />
                    <ImageDeleteModal
                        imageDeleteModalshow={imageDeleteModalshow}
                        handleImageDeleteModalClose={handleImageDeleteModalClose}
                        handleDeleteImage={handleDeleteImage}
                    />
                </>
            )}
        </>
    )
}

export default PlayerInformation
